import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Empresas from '../pages/Empresas';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import Usuarios from '../pages/Usuarios';
import { PrivateRoute } from './PrivateRoute';

export default function Rotas() {
  const loginData = localStorage.getItem('loggedData');
  return (
    <Router>
      <Routes>
        <Route path="/" element={loginData ? <Navigate to="/empresas" /> : <Navigate to="/login" />} />
        <Route path="/login" element={loginData ? <Navigate to="/empresas" /> : <Login />} />
        <Route path="/empresas" element={
          <PrivateRoute>
            <Empresas />
          </PrivateRoute>
        } />
        <Route path="/dashboard" element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        } />
        <Route path={`/usuario`} element={
          <PrivateRoute>
            <Usuarios />
          </PrivateRoute>
        } />
      </Routes>
    </Router >
  );
}