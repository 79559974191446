import Auth from "./Auth";

export interface AuthBodyType { userToken: string, username: string, userEmpresa: string }

const AttToken = async (): Promise<AuthBodyType | void> => {
  const localLoginData = typeof window !== 'undefined' ? window.localStorage.getItem('loggedData') : '{}';
  if (localLoginData) {
    const parsedLoginData: AuthBodyType = JSON.parse(localLoginData);
    const authService = new Auth(parsedLoginData.userToken, parsedLoginData.username, parsedLoginData.userEmpresa);
    const newToken = await authService.getToken();
    const data = { userToken: newToken, username: parsedLoginData.username, userEmpresa: parsedLoginData.userEmpresa };
    return data;
  }
}

export default AttToken;