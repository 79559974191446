import React, { useState } from 'react'
import { empresaType } from './EmpresasCadastradas';
import ReactInputMask from 'react-input-mask';
import { GiSave } from "react-icons/gi";
import { useMutation } from 'react-query';
import AttToken from '../../services/AttToken';
import Empresas from '../../services/Empresas';
import { toast, ToastContainer } from 'react-toastify';
import { Form, InputBox } from './styles';

const empresaService = new Empresas();

export default function CadastrarEmpresas() {
  const [empresaState, setEmpresaState] = useState<empresaType>({
    nome: '',
    cnpj: '',
    emailNotificacao: '',
    status: 'ativo'
  })

  const { mutate } = useMutation({
    mutationKey: ['PUT_EMPRESA'],
    mutationFn: async () => {
      try {
        const token = await AttToken()
        if (token) {
          const empresa: empresaType = {
            nome: empresaState.nome,
            cnpj: empresaState.cnpj,
            emailNotificacao: empresaState.emailNotificacao = '',
            status: empresaState.status
          }
          const response = await empresaService.novaEmpresa(
            token,
            empresa,
          )
          setEmpresaState({
            nome: '',
            cnpj: '',
            emailNotificacao: '',
            status: 'ativo'
          })

          return response
        }
      } catch (err) {
        setEmpresaState({} as empresaType)
        toast.error("Erro ao cadastrar!", {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          theme: "colored",
        })
      }
    },
    onSuccess: () => {
      setEmpresaState({} as empresaType)
      toast.success('Empresa cadastrada!', {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        theme: "colored",
      })
    },
    onError: () => {
      setEmpresaState({} as empresaType)
      toast.error("Erro ao cadastrar!", {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        theme: "colored",
      })
    }
  })

  return (
    <>
      <ToastContainer />
      <Form onSubmit={(event) => {
        event.preventDefault()
        mutate()
      }}>
        <InputBox>
          Nome:
          <input
            type='text'
            placeholder='Digite o nome da empresa'
            value={empresaState.nome}
            onChange={(event) => setEmpresaState({ ...empresaState, nome: event.target.value })}
            className={`input is-fullwidth`} />
          CNPJ:
          <ReactInputMask
            placeholder='Entre com o CNPJ'
            className={`input is-fullwidth`}
            value={empresaState.cnpj}
            onChange={(event) => setEmpresaState({ ...empresaState, cnpj: event.target.value })}
            mask="99.999.999/9999-99"
          />
        </InputBox>
        <button
          style={{ marginTop: '10px' }}
          disabled={empresaState.nome?.length <= 0 || empresaState.cnpj?.length <= 0}
          type='submit'
          className='button is-success is-small'>
          <GiSave />
          &nbsp;Salvar
        </button>
      </Form>
    </>
  )
}