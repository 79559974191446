import { useState } from "react";
import CadastrarEmpresas from './CadastrarEmpresas';
import EmpressasCadastradas from './EmpresasCadastradas';
import { ImOffice } from "react-icons/im";
import { IoCreateOutline } from "react-icons/io5";
import Header from '../Header/Header';
import { Main, TabStyle } from "./styles";


export default function EmpresasComponent() {
  const [isActive, setIsactive] = useState('tab1')

  const onclick = (value: string) => {
    setIsactive(value)
  }

  return (
    <Main>
      <Header />
      <span style={{ textAlign: 'center', width: '100%', fontSize: '30px' }}>Gestão de Empresas</span>
      <div style={{padding:'0px 5% 0px 5%'}}>
      <div className={`tabs is-boxed`}>
        <ul>
          <li className={isActive === "tab1" ? "is-active" : ""} onClick={() => onclick('tab1')}>
            <TabStyle><ImOffice />&nbsp;Empresas cadastradas</TabStyle>
          </li>
          <li className={isActive === "tab2" ? "is-active" : ""} onClick={() => onclick('tab2')}>
            <TabStyle>&nbsp;<IoCreateOutline />Criar empresa</TabStyle>
          </li>
        </ul>
      </div>
      </div>
      {isActive === 'tab1' ? <EmpressasCadastradas /> : <CadastrarEmpresas />}
    </ Main>
  );
}