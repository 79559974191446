import axios, { AxiosError } from "axios";
import { body } from "./Empresas";

class Users {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/usuarios`,
  });

  getAllByCompany = async (body: body, empresaId: string) => {
    try {
      const data = await this.api.post("/getAllByCompany", {
        ...body,
        empresaId
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.log("erro --->", error.response);
        console.error(error.message);
      }
    }
  };
}
export default Users;