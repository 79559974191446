import { empresaType } from './EmpresasCadastradas';
import { useMutation } from 'react-query';
import Empresas from '../../services/Empresas';
import AttToken from '../../services/AttToken';
import { toast, ToastContainer } from 'react-toastify';
import InputMask from 'react-input-mask';
import { ModalCardBody, ModalFooter, P, Span } from './styles';
import queryClient from '../../services/query';

type modalEditProps = {
  openModal: boolean,
  setOpenModal: Function,
  selectedEmpresa: empresaType,
  setSelectedEmpresa: Function,
}

const empresaService = new Empresas();

export default function ModalEditarEmpresa({ openModal, setOpenModal, selectedEmpresa, setSelectedEmpresa }: modalEditProps) {

  const { mutate, isLoading } = useMutation({
    mutationKey: ['PUT_EMPRESA'],
    mutationFn: async () => {
      try {
        const token = await AttToken()
        if (token) {
          const empresa = {
            id: selectedEmpresa.id as string,
            nome: selectedEmpresa.nome,
            cnpj: selectedEmpresa.cnpj,
            emailNotificacao: selectedEmpresa.emailNotificacao,
            status: selectedEmpresa?.status?.toLowerCase(),
          }
          const response = await empresaService.editaEmpresa(
            token,
            empresa,
          )
          setSelectedEmpresa({} as empresaType)
          queryClient.resetQueries(['GET_EMPRESAS'])
          setOpenModal(!openModal)
          return response
        }
      } catch (err) {
        setSelectedEmpresa({} as empresaType)
        toast.error("Erro ao cadastrar!", {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          theme: "colored",
        })
      }
    },
    onSuccess: () => {
      toast.success('Empresa cadastrada!', {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        theme: "colored",
      })
    },
    onError: () => {
      toast.error("Erro ao cadastrar!", {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        theme: "colored",
      })
    }
  })

  return (
    <>
      <ToastContainer />
      <div className={`${openModal ? 'modal is-active' : 'modal'}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className={`modal-card-head`} style={{ backgroundColor: '#f5f5f5', color: 'gray !important' }}>
            <P className={`modal-card-title`}>Editar empresa</P>
            <button
              onClick={() => setOpenModal(!openModal)}
              style={{ backgroundColor: 'red' }}
              className="is-danger delete"
              aria-label="fechar"/>
          </header>
          <ModalCardBody className={`modal-card-body`}>
            <Span>
              Nome:
            </Span>
            <input
              value={selectedEmpresa?.nome}
              onChange={(event) => setSelectedEmpresa({ ...selectedEmpresa, nome: event.target.value })}
              placeholder='Entre com o nome'
              className={`input is-fullwidth`}
            />
            <Span>
              CNPJ:
            </Span>
            <InputMask
                placeholder='Entre com o CNPJ'
              className={`input is-fullwidth`}
              value={selectedEmpresa?.cnpj}
              onChange={(event) => setSelectedEmpresa({ ...selectedEmpresa, cnpj: event.target.value })}
              mask="99.999.999/9999-99"
            />
            <Span>
              E-mail de notificação e pesquisa:
            </Span>
            <input
              value={selectedEmpresa?.emailNotificacao ? String(selectedEmpresa?.emailNotificacao) : ''}
              onChange={(event) => setSelectedEmpresa({ ...selectedEmpresa, emailNotificacao: event.target.value })}
              placeholder='Entre com o e-mail'
              className={`input is-fullwidth`} />
            <Span>
              Status:
            </Span>
            <select
              onChange={(event) => setSelectedEmpresa({ ...selectedEmpresa, status: event.target.value })}
              value={selectedEmpresa.status}
              className='select is-fullwidth'>
              <option value={('ativo').toLowerCase()}>Ativo</option>
              <option value={('inativo').toLowerCase()}>Inativo</option>
            </select>
          </ModalCardBody>
          <ModalFooter className={`modal-card-foot`}>
            <div className="buttons">
              <button disabled={isLoading} onClick={() => mutate()}className="button is-success">Salvar</button>
            </div>
          </ModalFooter>
        </div>
      </div>
    </>
  )
}