import axios, { AxiosError } from "axios";
import IUserData from "../interface/IUserData";


class Auth {
  private token: string;
  private username: string;
  private company: string;

  constructor(token: string, username: string, company: string) {
    this.token = token;
    this.username = username;
    this.company = company;
  }

  private api = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
  });

  confirmLoginAndGetData = async (): Promise<IUserData | undefined> => {
    try {
      const loginData = await this.api.post("/login/getToken", { loginToken: this.token, force: false });
      if (loginData && typeof window !== 'undefined') {
        window.localStorage.setItem(
          "loggedData",
          JSON.stringify({
            userToken: this.token,
            username: this.username,
            userEmpresa: this.company,
          })
        );
        const data = await this.api.post("/usuarios/getUser", {
          userToken: loginData.data.token,
          username: this.username,
          userEmpresa: this.company,
        });
        return data.data.user;
      }
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.log("erro --->", error.response);
      }
    }
  };

  getToken = async () => {
    try {
      const data = await this.api.post("/login/getToken", { loginToken: this.token, force: true });
      return data.data.token;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.log("erro --->", error.response);
      }
    }
  };
}

export default Auth;