import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import AxymaLogo from '../images/Axyma_branco.svg';
import Oval from 'react-loading-icons/dist/esm/components/oval';


function PrivateRoute({ children }: { children: React.ReactComponentElement<any> }) {
  const [authUser, setAuthUser] = useState(false);
  const [stopper, setStopper] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAccess = (userDataParam: { userName: string }) => {
      if (userDataParam.userName === 'Administrador Axyma') {
        setAuthUser(true);
      } else {
        setAuthUser(false);
      }
      setStopper(true);
    };

    setTimeout(() => {
      const userDataLocal = localStorage.getItem('userData');
        if (userDataLocal) {
          checkAccess(JSON.parse(userDataLocal));
        } else {
          checkAccess({userName: ''});
        }
      }, 2000)
  }, [stopper, navigate]);

  if (stopper) {
    return authUser ? children : <Navigate to="/login" />
} else return (
  <div style={{ backgroundColor: '#3a1c64', display: 'flex', flexDirection: 'column', height:'100vh', alignItems: 'center', justifyContent:'space-evenly' }}>
    <img src={AxymaLogo} alt="logo axyma branco" width={'40%'}/>
    <Oval />
  </div>
  )
}

export { PrivateRoute };