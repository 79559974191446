import styled from "styled-components";

export const Main = styled.main`
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  padding: 0px 100px 0px 100px;
  border: 0;
  font-size: 13px;
  height: fit-content;
  width: 100svw;
`;

export const Principal = styled.div`
  background-color: white;
  justify-content: center;
  padding: 20px 100px 100px 100px;
  height: fit-content;
  min-height: fit-content;
  background-color: white;
  width: 100%;
`;
