import styled from "styled-components";

export const Main = styled.main`
  height: 100svh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #643e8f;
`;

export const Form = styled.form`
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
`;
export const ButtonDiv = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
`;

export const Input = styled.input`
  margin: 10px 0px 10px 0px;
`;

// .input {
//   background-color: white;
//   color: black;
//   margin: 10px 0px 10px 0px;
//   width: 100%;
// }

// .input::placeholder {
//   color: gray;
// }`
