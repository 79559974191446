
import { QueryClientProvider } from 'react-query';
import './App.css';
import Rotas from './routes/routes';
import queryClient from './services/query';
import { ToastContainer } from 'react-toastify';

const title = document.getElementById('title');

if (title) title.innerText = `${process.env.REACT_APP_PROJECT_NAME}`

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <Rotas />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
