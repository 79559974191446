import Header from '../Header/Header';
// import Empresas from '../../services/Empresas';
// import { useQuery } from 'react-query';
// import AttToken from '../../services/AttToken';
// import { empresaType } from '../Empresas/EmpresasCadastradas';
// import { Skeleton } from '@mui/material';
import { Content, Main } from './styles';

// const empresaService = new Empresas();

export default function UsuarioComponent() {

  // const { data, isLoading, isFetching, refetch } = useQuery({
  //   queryKey: ['GET_EMPRESAS'],
  //   queryFn: async () => {
  //     const token = await AttToken()
  //     if (token) {
  //       const response = await empresaService.getAll(token)
  //       return response.empresas
  //     }
  //   },
  //   keepPreviousData: true,
  //   retry: 5,
  //   refetchOnWindowFocus: false,
  //   refetchOnMount: 'always',
  // })

  return (
    <Main data-theme="light">
      <Header />
      <span style={{ textAlign: 'center', width: '100%', fontSize: '30px' }}>Gestão de Usuarios</span>
      <Content>
        <span style={{ textAlign: 'center', width: '100%', fontSize: '30px' }}>Em construção...</span>
        {/* <div className={styles.principal}>
          {isLoading ?
            <>
              <Skeleton width={120} />
              <Skeleton height={60} style={{ marginTop: '-.9em' }} />
            </>
            :
            <>
              Selecione um usuário:
              <div className={`select is-fullwidth ${styles.select}`}>
                <select>
                  {data?.sort((a: empresaType, b: empresaType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((elem: empresaType) => (
                      <option>
                        {elem.nome}
                      </option>
                    ))}
                </select>
              </div>
            </>
          }
        </div> */}
      </Content>
    </ Main>
  )
}