import { useState } from 'react'
import { DataGrid, GridColDef, GridCellParams, GridLocaleText } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales'
import clsx from 'clsx';
import AttToken from '../../services/AttToken';
import Empresas from '../../services/Empresas';
import { useQuery } from 'react-query';
import { Skeleton } from '@mui/material';
import ModalEditarEmpresa from './ModalEditarEmpresa';
import Switch from '@mui/material/Switch';
import { toast, ToastContainer } from 'react-toastify';
import { ButtonDiv, Form } from './styles';

const empresaService = new Empresas();

export type empresaType = {
  id?: string,
  nome: string,
  cnpj: string,
  status: string,
  emailNotificacao: string | null,
  dataCadastro?: number,
  formMigrado?: boolean,
  migrado?: boolean,
  planoDeAcao?: boolean
}

export default function EmpressasCadastradas() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [blockButtons, setBlockButtons] = useState<boolean>(false)
  const [selectedEmpresa, setSelectedEmpresa] = useState<empresaType>({} as empresaType)

  const migraForm = async (id: string, migrado: boolean) => {
    try {
      setBlockButtons(true);
      const token = await AttToken()
      if (token) {
        await empresaService.migraForm(token, id, migrado ? !migrado : true);
        setBlockButtons(false);
        return refetch()
      }
    } catch (err) {
      return err
    }
  }

  const migraChamados = async (id: string, migrado: boolean) => {
    try {
      setBlockButtons(true);
      const token = await AttToken()
      if (token) {
        await empresaService.ligaDesligaMigracao(token, migrado, id);
        setBlockButtons(false);
        return refetch()
      }
    } catch (err) {
      return err
    }
  }

  const ligaDesligaPlano = async (id: string, migrado: boolean) => {
    try {
      setBlockButtons(true);
      const token = await AttToken()
      if (token) {
        await empresaService.concedeRetiraAcessoPlanoDeAcao(token, migrado, id);
        setBlockButtons(false);
        return refetch()
      }
    } catch (err) {
      return err
    }
  }

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['GET_EMPRESAS'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await empresaService.getAll(token)
        return response.empresas
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const columns: GridColDef[] = [
    { field: 'nome', headerName: 'Empresa', width: 400 },
    { field: 'cnpj', headerName: 'CNPJ', width: 150 },
    { field: 'dataCadastro', headerName: 'Data de Cadastro', width: 160 },
    {
      field: 'status', headerName: 'Status', width: 65, type: 'string', cellClassName: (params: GridCellParams) => {
        return clsx('super-app', {
          positive: params.value === 'Ativo',
          negative: params.value === 'Inativo',
        });
      }
    },
    {
      field: "edit",
      headerName: "Editar empresa",
      sortable: false,
      width: 130,
      renderCell: (params) => (
        <ButtonDiv>
          <button onClick={(event) => {
            event.preventDefault();
            const empresa = data.find((elem: empresaType) => elem.id === params.id)
            setSelectedEmpresa(empresa)
            if(selectedEmpresa)
            setOpenModal(!openModal)
          }}
            disabled={blockButtons}
            className='button is-small'>
            Editar empresa
          </button>
        </ButtonDiv>
      )
    },
    {
      field: "formMigrado",
      headerAlign:'center',
      headerName: `Estrutura de Forms\nMigrados (Tipo de Serviço)`,
      sortable: false,
      width: 180,
      renderCell: (params) => (
        <ButtonDiv>
          <Switch onChange={async () => {
            setBlockButtons(true);
            await toast.promise(migraForm(String(params.id), params.row.formMigrado), {
              pending: 'Migrando formulários',
              success: 'Formulários migrados',
              error: 'Erro ao migrar formulários',
            })
          }} defaultChecked={params.row.formMigrado} color='success' disabled={blockButtons}/>
        </ButtonDiv>
      )
    },
    {
      field: "migrado",
      headerName: "Chamado/Notificações 2.0",
      sortable: false,
      width: 180,
      renderCell: (params) => (
        <ButtonDiv>
          <Switch onChange={async () => {
            setBlockButtons(true);
            const migrado = typeof params.row.migrado === 'boolean' ? !params.row.migrado : true
            await toast.promise(migraChamados(String(params.id), migrado), {
              pending: `Mudando empresa ${params.row.nome} para Chamados/Notificações ${!migrado ? 'Legado' : '2.0'}...`,
              success: `Empresa ${params.row.nome}, Agora usa Chamados/Notificações ${!migrado ? 'Legado' : '2.0'}`,
              error: 'Erro',
            })
          }} defaultChecked={params.row.migrado} color='success' disabled={blockButtons}/>
        </ButtonDiv>
      )
    },
    {
      field: "planoDeAcao",
      headerName: "Modulo de Plano de Ação",
      sortable: false,
      width: 180,
      renderCell: (params) => {
        return (
        <ButtonDiv>
          <Switch onChange={async () => {
            setBlockButtons(true);
            const migrado = typeof params.row.planoDeAcao === 'boolean' ? !params.row.planoDeAcao : true
            await toast.promise(ligaDesligaPlano(String(params.id), migrado), {
              pending: `${!migrado ? 'Desligando' : 'Ligando'} Modulo de Planos de Ação para empresa ${params.row.nome}...`,
              success: `Modulo de Planos de Ação ${!migrado ? 'Desligado' : 'Ligado'} para empresa ${params.row.nome}`,
              error: 'Erro',
            })
          }} defaultChecked={params.row.planoDeAcao} color='success' disabled={blockButtons}/>
        </ButtonDiv>
      )}
    },
  ];

  const ptBRGrid: Partial<GridLocaleText> = {
    footerRowSelected: (count) =>
      count !== 1
        ? ``
        : ``,
  }
  return (
    <Form>
      <ToastContainer />
      {
        isLoading || isFetching ? <Skeleton height={900} width={'100%'} style={{ marginTop: '-12em' }} /> :
          <div>
            {openModal
              && <ModalEditarEmpresa
                selectedEmpresa={selectedEmpresa}
                openModal={openModal}
                setOpenModal={setOpenModal}
                setSelectedEmpresa={setSelectedEmpresa}
              />}
            {data ? <DataGrid
              rows={data?.map((elem: empresaType, index: number) => {
                return ({
                  id: elem.id,
                  nome: elem?.nome,
                  cnpj: elem?.cnpj,
                  dataCadastro: elem?.dataCadastro && new Date(elem?.dataCadastro)?.toLocaleString('pt-BR'),
                  status: elem?.status === 'ativo' ? 'Ativo' : 'Inativo',
                  edit: elem.id,
                  formMigrado: elem?.formMigrado,
                  migrado: elem?.migrado,
                  planoDeAcao: elem?.planoDeAcao
                })
              })}
              sx={{
                '& .super-app.negative': {
                  backgroundColor: '#F14668',
                  color: 'white',
                  textAlign: 'center'
                },
                '& .super-app.positive': {
                  backgroundColor: '#00D1B2',
                  color: 'white',
                  textAlign: 'center'
                },
              }}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 25]}
              localeText={ptBRGrid && ptBR.components.MuiDataGrid.defaultProps.localeText}
            /> : <div style={{ textAlign: 'center' }}>Não encontrou empresas cadastradas</div>}
          </div>
      }
    </Form>
  )
}